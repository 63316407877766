import './SideBar.css';
import React from 'react';
import {AppContext} from "../App";
import {Link, useNavigate} from "react-router-dom";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TournamentBox from "../boxes/TournamentBox";
import ArticleComponent from "../pages/StaticArticleComponent";
import AdBox from "../boxes/AdBox";
import CommunityPickBox from "../boxes/CommunityPickBox";

class SideBar extends React.Component {
    constructor(props) {
        super(props);
    }

    openUri(e, uri) {
        e.preventDefault();
        this.props.navigate(uri);
    }

    _getCommunity() {
        return this.props.context?.components?.community;
    }

    _getTopItems() {
        const top_items = this._getCommunity()?.top || null;
        if (!top_items) {
            return null;
        }
        let item_cnt = 1;
        const list_items = top_items.map((top_item) => {
            return (
                <li>
                    <span>{item_cnt++}.</span>
                    <Link to={top_item.link}>
                        {top_item.topic}
                    </Link>
                    <div className="TopList-Community-State">
                        <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {top_item.likes} <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {top_item.dislikes} <FontAwesomeIcon icon={icon({name: 'comment'})} /> {top_item.comments}
                    </div>
                </li>
            )
        }); // <FontAwesomeIcon icon={icon({name: 'eye'})} /> {top_item.views} /
        return list_items;
    }

    _getCalendar() {
        return (
            <>
                <TournamentBox />
            </>
        )
    }

    _getCommunityPick() {
        return (
            <CommunityPickBox position="sidebar" />
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ArticleComponent.handleDynamicComponents();
    }

    _getCategories() {
        if (!this.props?.categories) {
            return null;
        }
        let tags = [];
        for (let category of this.props.categories) {
            if (!category?.title) {
                continue;
            }
            tags.push((
                <>
                    <a href={category.uri} onClick={(e) => { this.openUri(e, category.uri); }}>#{category.title}</a>
                </>
            ));
        }

        if (tags.length === 0) {
            return null;
        }
        return (
            <>
                <div className="Community-Tags-Box">
                    <h2 className="Community-Tags-Title">Tagit</h2>
                    {tags}
                </div>
            </>
        );
    }

    render() {
        if (this.props?.resources?.length) {
            // Custom sidebar
            let sidebar_items = [];
            for (const sidebar_item of this.props.resources) {
                let title = null;
                if (sidebar_item?.show_topic) {
                    title = (
                        <h2 className="Community-Top-List-Title">{sidebar_item.topic}</h2>
                    );
                }

                sidebar_items.push((
                    <div>
                        {title}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sidebar_item.content,
                            }}/>
                    </div>
                ));
            }
            return (
                <section className="SideBar">
                    {sidebar_items}
                </section>
            );
        }

        // Indicate loading
        let community_element;
        if (this._getCommunity()?.top?.length > 0) {
            community_element = (
                <>{this._getTopItems()}</>
            );
        } else {
            community_element = (
                <>
                    <li>................</li>
                    <li>................</li>
                    <li>................</li>
                    <li>................</li>
                    <li>................</li>
                </>
            );
        }
        // Default sidebar
        return (
            <section className="SideBar">
                <div>
                    <h2 className="Community-Top-List-Title">Suosituimmat</h2>
                    <ul>
                        {community_element}
                    </ul>
                </div>

                {this._getCommunityPick()}

                <AdBox position="sidebar" />

                {this._getCalendar()}

                {this._getCategories()}
            </section>
        );
    }
}

const SideBarWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <SideBar {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default SideBarWithContext;

