import './CommunityBox.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Loading from "../Loading";
import UserTextWithMarkup from "../elements/UserTextWithMarkup";
import AdBox from "./AdBox";
import WarningNote from "../elements/WarningNote";

class CommunityBox extends React.Component {
    constructor(props) {
        super(props);
        const article = this.props?.article;
        this.state = {
            'comments': {},
            'is_loading': true,
        };
        if (article) {
            this.state.comments = {};
            this.state.comments[article.id] = [];
        }
        this._getComments();
    }

    _getApi() {
        return new WebsiteApi();
    }

    _getComments() {
        const article = this.props?.article;
        this._getApi().getComments(article.id).then((data) => {
            let comments = this.state.comments || {};
            comments[article.id] = data;
            this.setState({
                'comments': comments,
                'is_loading': false,
            });
        });
    }

    _localCommentExists(comment_id) {
        let local_comments_all = this.state.comments || {};
        for (let resource_id in local_comments_all) {
            let local_comments = local_comments_all[resource_id];
            for (let i in local_comments) {
                let local_comment = local_comments[i];
                if (!local_comment || !local_comment?.id) {
                    continue;
                }
                if (parseInt(local_comment.id, 10) === parseInt(comment_id, 10)) {
                    return true;
                }
            }
        }
        return false;
    }

    _updateLocalComment(comment_id, data) {
        let local_comments_all = this.state.comments || {};
        for (let resource_id in local_comments_all) {
            let local_comments = local_comments_all[resource_id];
            for (let i in local_comments) {
                let local_comment = local_comments[i];
                if (!local_comment || !local_comment?.id) {
                    continue;
                }
                if (parseInt(local_comment.id, 10) !== parseInt(comment_id, 10)) {
                    // Not target comment
                    continue;
                }
                for (let key in data) {
                    local_comments_all[resource_id][i][key] = data[key];
                }
            }
        }
        this.setState({
            'comments': local_comments_all,
        });
    }

    likeComment(event, comment_id) {
        const article = this.props?.article;
        if (!event || !event.target) {
            return false;
        }
        event.preventDefault();
        this._updateLocalComment(comment_id, {'is_loading': true});

        this._getApi().likeResource({
            'resource_id': article.id,
            'comment_id': comment_id,
        }).then((data) => {
            this._updateLocalComment(comment_id, {'likes': data?.likes, 'dislikes': data?.dislikes, 'own_interaction': 1, 'is_loading': false});
        }).catch((error) => {
            this._updateLocalComment(comment_id, {'is_loading': false});
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            alert(error_msg); // @todo BETTER ERROR UX
        });
        return false;
    }

    dislikeComment(event, comment_id) {
        const article = this.props?.article;
        if (!event || !event.target) {
            return false;
        }
        event.preventDefault();
        this._updateLocalComment(comment_id, {'is_loading': true});

        this._getApi().dislikeResource({
            'resource_id': article.id,
            'comment_id': comment_id,
        }).then((data) => {
            this._updateLocalComment(comment_id, {'likes': data?.likes, 'dislikes': data?.dislikes, 'own_interaction': -1, 'is_loading': false});
        }).catch((error) => {
            this._updateLocalComment(comment_id, {'is_loading': false});
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            if (error_msg === 'VISITORS_NOT_ALLOWED_TO_DISLIKE') {
                localStorage.setItem(`signup_return_uri`, article.link); // Save return URL
                this.props.navigate('/pelaaja');
                return;
            }
            alert(error_msg); // @todo BETTER ERROR UX
        });
        return false;
    }

    postComment(event) {
        const article = this.props?.article;
        if (!event || !event.target) {
            return false;
        }
        //const comment = event.target.value ; // <-- THIS IS ONLY BUTTON "value"
        const comment = this.refs.newComment.value;
        event.preventDefault();

        this.setState({
            'is_posting_new_comment': true,
        });

        this._getApi().postComment({
            'resource_id': article.id,
            'comment': comment
        }).then((data) => {
            let joined_comments = this.state.comments;
            joined_comments[article.id].push(data);
            this.setState({
                'comments': joined_comments,
                'is_posting_new_comment': false,
            });
            this.refs.newComment.value = '';
            // @todo
        }).catch((error) => {
            this.setState({
                'is_posting_new_comment': false,
            });
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            alert(error_msg); // @todo BETTER ERROR UX
        });
        return false;
    }

    _onAdminCommentActionCallback(comment_id, action, data) {
        if (!comment_id) {
            return;
        }
        if (action !== 'DELETE') {
            return;
        }
        if (!data) {
            return;
        }
        this._updateLocalComment(comment_id, {'status': 99}); // Mark as deleted
    }

    _onAdminActionCallback(entity, entity_id, action, data) {
        switch (entity) {
            case 'comment':
                return this._onAdminCommentActionCallback(entity_id, action, data);
        }
    }

    _getCommentLikeControls(comment) {
        if (!comment) {
            return (<div />)
        }
        let additional_controls;
        if (window?._Admin) {
            additional_controls = window._Admin.getAdminCommentControls(comment.id, this._onAdminActionCallback.bind(this));
        }
        return (
            <div>
                {additional_controls}
                <button aria-label="Anna kommentille peukku" onClick={ (event) => { this.likeComment(event, comment.id); } } disabled={comment?.author?.is_own || this.state.is_loading || comment?.is_loading || comment?.own_interaction > 0}>
                    <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {comment.likes}
                </button>
                &nbsp;&mdash;&nbsp;
                <button aria-label="Anna kommentille alapeukku" onClick={ (event) => { this.dislikeComment(event, comment.id); } } disabled={comment?.author?.is_own || this.state.is_loading || comment?.is_loading || comment?.own_interaction < 0}>
                    <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {comment.dislikes}
                </button>
            </div>
        )
    }

    _getCommentContent(comment) {
        if (!comment?.txt) {
            return (<div />)
        }
        let author_img = null;
        if (comment?.author?.img) {
            author_img = (
                <div>
                    <img src={comment.author.img} alt={`Kirjoittajan avatar: ${comment?.author?.name}`} />
                </div>
            );
        }
        const comment_anchor = 'kommentti-' + comment.id;
        const anchor_uri = localStorage.getItem(`anchor_uri`);
        if (anchor_uri && anchor_uri.includes(`#${comment_anchor}`) && window.location.href.indexOf(anchor_uri) === -1) {
            setTimeout(() => {
                window.location.hash = '#' + comment_anchor;
            }, 500);
            localStorage.removeItem(`anchor_uri`);
        }
        let comment_actions = null;
        if (!comment?.loading_placeholder) {
            comment_actions = (
                <div className="Comment-Community-Actions">
                    {this._getCommentLikeControls(comment)}
                </div>
            );
        }
        let author_name = comment?.author?.name || '';
        if (comment?.author?.is_own) {
            author_name = '(sinä)';
        }

        return (
            <div className="Comment-Wrapper">
                <a name={comment_anchor} className="Comment-Link-Anchor"></a>
                <div className="Comment-Info-Sidebar">
                    <div className="Comment-Author-Profile-Details">
                        {author_img} {author_name}
                    </div>
                    <div className="Comment-Time">
                        {comment?.published_ago}
                    </div>
                    {comment_actions}
                </div>

                <UserTextWithMarkup text={comment.txt} />
            </div>
        );
    }

    getCommentsContent() {
        const article = this.props?.article;
        if (!this.state.comments || !this.state.comments[article.id] || this.state.is_loading) {
            return (
                <>
                    <Loading />
                </>
            );
        }
        let comments = [];
        for (let comment of this.state.comments[article.id]) {
            if (comment?.status === 99) {
                // Deleted, skip
                continue;
            }
            comments.push(this._getCommentContent(comment));
            if (comments.length === 10) {
                // Add an ad to the comments section after 10 comments
                comments.push((
                    <AdBox position="community" />
                ));
            }
            if (comments.length > 99 && comments.length % 100 === 0) {
                // Add an ad to the comments section after every 100 comments
                comments.push((
                    <AdBox position="community" />
                ));
            }
        }
        if (this.state.is_posting_new_comment) {
            // Indicate loading until comment has been posted
            comments.push(this._getCommentContent({txt: (<Loading inline={true} />), loading_placeholder: true}));
        }
        return comments;
    }

    getNewCommentBoxContent() {
        const article = this.props?.article;
        const commentsDisabled = `${article?.community_status}` !== "0";
        let warning;
        if (commentsDisabled) {
            warning = (
                <WarningNote mode="warning" title="Kommentointi tälle sivulle on lukittu tilapäisesti." />
            );
        }
        return (
            <div className="Write-Comment-Wrapper">
                {warning}
                <textarea aria-label="Kommenttikenttä, kirjoita uusi kommentti" ref="newComment" disabled={this.state.is_loading || commentsDisabled}></textarea>
                <button aria-label="Lähetä kommentti" onClick={ (event) => { this.postComment(event); } } disabled={this.state.is_loading || commentsDisabled}>KOMMENTOI</button>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const article = this.props?.article;
        if (!this.state.comments || !this.state.comments[article.id] || this.state.is_loading) {
            if (article.id && !this.state.comments[article.id]) {
                this._getComments();
            }
        }
        if (this.props.context?.comments) {
            // receive:updates has provided comments -> update comments
            let new_comments_received = false;
            let joined_comments = this.state.comments;
            for (let comment of this.props.context.comments) {
                if (!comment || !comment?.id) {
                    continue;
                }
                if (comment.resource_id !== article.id) {
                    // E.g. page changed, but updates are for previous page
                    continue;
                }
                if (this._localCommentExists(comment.id)) {
                    continue;
                }
                // ..okay, we got a new comment!
                new_comments_received = true;
                joined_comments[article.id].push(comment);
            }
            if (new_comments_received) {
                this.setState({
                    'comments': joined_comments,
                });
            }
        }
    }

    render() {
        const article = this.props?.article;
        if (!article || !article.topic) {
            return (
                <div />
            )
        }
        return (
            <div className="Community-Box">
                <h2 className="Community-Box-Title">Yhteisö</h2>
                {this.getCommentsContent()}

                {this.getNewCommentBoxContent()}
            </div>
        )
    }
}

const CommunityBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default CommunityBoxWithContext;

