import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import WebsiteApi from "../WebsiteApi";
import ReservationCalendar from "../components/ReservationCalendar";
import SaunaReservationCalendar from "../components/SaunaReservationCalendar";

class ArticleComponent extends Component {
    _getApi() {
        return new WebsiteApi();
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleEmbeds();
    }

    componentDidMount() {
        if (!window?._twitterEmbedCache) {
            window._twitterEmbedCache = {};
        }
        this.handleEmbeds();
        ArticleComponent.handleDynamicComponents();
    }

    shouldComponentUpdate(nextProps) {
        return this.props.article.id !== nextProps.article.id;
    }

    static handleDynamicComponents() {
        if (window.updateSliders) {
            // Update image sliders
            window.updateSliders(true);
        }

        const available_components = {
            'ReservationCalendar': ReservationCalendar,
            'SaunaReservationCalendar': SaunaReservationCalendar,
        }

        const component_placeholders = document.querySelectorAll('div[data-component*="DC:"]');
        const filtered_component_placeholders = Array.from(component_placeholders).filter(element => {
            return element.closest('.ck-editor') === null;
        });
        for (const component_placeholder of filtered_component_placeholders) {
            const component = component_placeholder.getAttribute('data-component').replace('DC:', '');
            if (!available_components[component]) {
                console.error(`Component not found: ${component}`);
                continue;
            }
            const element = React.createElement(available_components[component], {rendering: true});
            component_placeholder.removeAttribute('data-component');
            const dynamic_component_root = ReactDOM.createRoot(component_placeholder);
            dynamic_component_root.render(element);
            //
            /*const dynamic_article = article.content.replace(component_placeholder.outerHTML, component_placeholder.outerHTML.replace(component_placeholder.innerHTML, `<${component} rendering={true} />`));
            this.setState({
                article: dynamic_article,
            })*/
        }
    }

    handleEmbeds() {
        document.querySelectorAll( 'oembed[url]' ).forEach( element => {
            const url = element?.attributes?.url?.value;
            if (!url) {
                return;
            }
            if (url.includes('www.youtube.com/watch?v=') || url.includes('://youtu.be/') || url.includes('https://www.youtube.com/embed/')) {
                let embed_url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
                embed_url = embed_url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                embed_url = embed_url + '?feature=oembed&rel=0';
                const html = `<iframe width="100%" height="295" src="${embed_url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="" class="Youtube-Embed"></iframe>`;
                element.innerHTML = '' + html;
                element.setAttribute('data-original-url', url);
                element.attributes.url.value = '';
                return;
            }
            if (url.includes('://bsky.app/')) {
                /**
                 * "TWEET"
                 * <blockquote class="bluesky-embed" data-bluesky-uri="at://did:plc:5siz7r23475fek2mj6p5zhzw/app.bsky.feed.post/3ljaollbus22h" data-bluesky-cid="bafyreidsvv6twfj3gndrogo32l4t2xqi2bjxlnfeenzc64vr3foitat6pe"><p lang="en">The preparations meant Musk could hit the ground running on Jan. 20. Lately, he&#x27;s been working from the Secretary of War Suite in the Eisenhower Building, where he&#x27;s installed a gaming PC. Here&#x27;s what the setup looks like:<br><br><a href="https://bsky.app/profile/did:plc:5siz7r23475fek2mj6p5zhzw/post/3ljaollbus22h?ref_src=embed">[image or embed]</a></p>&mdash; kate conger (<a href="https://bsky.app/profile/did:plc:5siz7r23475fek2mj6p5zhzw?ref_src=embed">@kateconger.com</a>) <a href="https://bsky.app/profile/did:plc:5siz7r23475fek2mj6p5zhzw/post/3ljaollbus22h?ref_src=embed">Feb 28, 2025 at 17.05</a></blockquote>
                 *
                 * SCRIPT: <script async src="https://embed.bsky.app/static/embed.js" charset="utf-8"></script>
                 */
                const onEmbedHtmlReceived = (html) => {
                    element.innerHTML = '' + html;
                    element.setAttribute('data-original-url', url);
                    element.attributes.url.value = '';
                    this._injectBlueskyEmbedScript();
                };
                if (window._twitterEmbedCache[url]) {
                    onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    return;
                }
                if (window._twitterEmbedCache[url] === false) {
                    return;
                }
                this._getApi().getResourceEmbed(url, (e) => {
                    window._twitterEmbedCache[url] = false;
                    console.error(`FAILED TO GET EMBED URL FOR ${url}`);
                }).then((html) => {
                    if (html) {
                        window._twitterEmbedCache[url] = html;
                        onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    }
                });
            }
            if (url.includes('://www.twitter.com') || url.includes('://twitter.com')) {
                // Handle Twitter embeds
                const onEmbedHtmlReceived = (html) => {
                    element.innerHTML = '' + html;
                    element.setAttribute('data-original-url', url);
                    element.attributes.url.value = '';
                    this._injectTwitterEmbedScript();
                };
                if (window._twitterEmbedCache[url]) {
                    onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    return;
                }
                if (window._twitterEmbedCache[url] === false) {
                    return;
                }
                this._getApi().getResourceEmbed(url, (e) => {
                    window._twitterEmbedCache[url] = false;
                    console.error(`FAILED TO GET EMBED URL FOR ${url}`);
                }).then((html) => {
                    if (html) {
                        window._twitterEmbedCache[url] = html;
                        onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    }
                });
            }
            if (url.includes('://www.reddit.com') || url.includes('://reddit.com')) {
                // Handle Reddit embeds
                /**
                 * STORY: e.g. https://www.reddit.com/r/valheim/comments/m5mfaw/valheim_ceo_confirms_no_ore_teleporting/
                 * <blockquote class="reddit-embed-bq" style="height:500px" data-embed-height="546"><a href="https://www.reddit.com/r/Games/comments/1ca8233/valheim_ashlands_gameplay_trailer/">Valheim: Ashlands Gameplay Trailer</a><br> by<a href="https://www.reddit.com/user/Miss__Solstice/">u/Miss__Solstice</a> in<a href="https://www.reddit.com/r/Games/">Games</a></blockquote>
                 * COMMENT: e.g. https://www.reddit.com/r/Games/comments/1ca8233/comment/l0q8gka/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button
                 * <blockquote class="reddit-embed-bq" data-embed-showtitle="true" data-embed-height="288"><a href="https://www.reddit.com/r/valheim/comments/m5mfaw/comment/gr0v9h3/">Comment</a><br> by<a href="https://www.reddit.com/user/Flooo134/">u/Flooo134</a> from discussion<a href="https://www.reddit.com/r/valheim/comments/m5mfaw/valheim_ceo_confirms_no_ore_teleporting/"><no value=""></no></a><br> in<a href="https://www.reddit.com/r/valheim/">valheim</a></blockquote>
                 *
                 * SCRIPT: <script async="" src="https://embed.reddit.com/widgets.js" charset="UTF-8"></script>
                 */
                const onEmbedHtmlReceived = (html) => {
                    element.innerHTML = '' + html;
                    element.setAttribute('data-original-url', url);
                    element.attributes.url.value = '';
                    this._injectRedditEmbedScript();
                };
                const clean_url = url.split('?')[0];
                const html = '<blockquote class="reddit-embed-bq"><a href="' + clean_url + '">Title</a><br></blockquote>';
                onEmbedHtmlReceived(html);
            }
        } );
    }

    _injectBlueskyEmbedScript() {
        this._injectEmbeddingScript('https://embed.bsky.app/static/embed.js');
    }

    _injectEmbeddingScript(scriptURL) {
        /*if (/googlebot/i.test(navigator.userAgent)) {
            // Twitter embeds are blocked by Twitter's robot.txt so don't even try to load them
            return;
        }*/
        const existing_script_element = document.querySelector(`script[src*="${scriptURL}"]`);
        if (existing_script_element) {
            if (scriptURL.includes('twitter.com') && window?.twttr?.widgets?.load) {
                // Re-use old script if possible
                window.twttr.widgets.load();
                return;
            }
            if (scriptURL.includes('bsky.app') && window?.bluesky?.scan) {
                window.bluesky.scan();
                return;
            }
            // Remove previous script inclusion to re-init after DOM updates to re-render elements
            existing_script_element.remove();
        }

        const script = document.createElement('script');
        script.async = true;
        script.crossorigin = "anonymous";
        script.src = scriptURL;
        script.charset = 'utf-8';
        document.head.appendChild(script);
    }

    _injectTwitterEmbedScript() {
        this._injectEmbeddingScript('https://platform.twitter.com/widgets.js');
    }

    _injectRedditEmbedScript() {
        this._injectEmbeddingScript('https://embed.reddit.com/widgets.js');
    }

    render() {
        const {article} = this.props;
        // Inject ad scripts
        let pCount = 0;
        let enhanced_content = article.content;

        if (article?.type === 'news') {
            enhanced_content.replace(/<\/p>/gi, (match) => {
                pCount++;
                // Insert after 2nd, 5th, 8th, and 10th occurrence
                if (pCount === 2) {
                    return match + '<div data-desktop-ad-unit-id="/22871113322/pelaajatcom_outstream_1_1" data-mobile-ad-unit-id="/22871113322/pelaajatcom_outstream_1_1"></div><div id="PM-outstream"></div>';
                }
                if (pCount === 4) {
                    return match + '<div class="Smart-Article-Ads"><div data-desktop-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_1_desktop" data-mobile-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_2"></div></div>';
                }
                if (pCount === 7) {
                    return match + '<div class="Smart-Article-Ads"><div data-desktop-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_2_desktop" data-mobile-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_3"></div></div>';
                }
                if (pCount === 10) {
                    return match + '<div class="Smart-Article-Ads"><div data-mobile-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_4"></div></div>';
                }
                if (pCount === 12) {
                    return match + '<div class="Smart-Article-Ads"><div data-mobile-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_5"></div></div>';
                }
                return match;
            });
        }
        return (
            <article dangerouslySetInnerHTML={{ __html: enhanced_content }} />
        );
    }
}

export default ArticleComponent;
